import React from "react"
import Layout from "../../components/layout"
import Card from 'react-bootstrap/Card'

export default ({data}) => (
  <Layout>
  <hr className="mt-0 mb-4 d-none d-md-block" />
    <div class="container">
      <div className="row">
          <Card className="border-0 mb-4">
            <div class="row">
              <div class="col-md-6 mb-4">
                <img src="https://www.crampete.com/wp-content/uploads/2020/04/116-1459x1536.jpg" alt="Contest random quote generator" />
              </div>
              <div class="col-md-6 pl-4 pr-4">
                <Card.Title style={{fontSize: "1.5rem"}}>Crampete Coding Contest #3 – Build a Random Quotes Generator</Card.Title>
                <p>Participate in Crampete's weekly coding contest #3 and sharpen your JavaScript skills. Participants will receive a Crampete certificate.</p>
                <p>This week's contest: Build a random quotes generator. You can find the quotes generator here</p>
                <p>Deadline: 12 pm on Friday(24/April/2020)</p>
                <p>Rules:</p>
                <ol style={{paddingLeft: "15px"}}>
                  <li>You can use HTML, JavaScript, and CSS  to develop a quote generator.</li>
                  <li>Submit your entries by 12 pm on Friday(24/April/2020).</li>
                  <li>One person can make only one submission.</li>
                  <li>The winner will be evaluated on the basis of design replication, code quality, code readability and absence of bugs.</li>
                  <li>You shall upload your submissions to a cloud drive (like Google Drive or Dropbox), make the folder public, and share the URL with us using the submit button below. We're unable to accept email submissions.</li>
                  <li>The winner's name will be published here on the following Wednesday; we'll also directly intimate the winner by email.</li>
                </ol>
              </div>
            </div>
          </Card>
      </div>
    </div>
  </Layout>
)
